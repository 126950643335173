import { object } from 'prop-types';
import { getCurrentRole } from 'src/services/Roles/Merchants';

const ALLOWED = '1';
const DENIED = '0';
const NOT_SET = '';

export const AUTH_KEY = {
  name: 'name',
  dashboard: 'dashboard',
  products: 'products',
  product_actions: 'product_actions',
  suppliers: 'suppliers',
  supplier_actions: 'supplier_actions',
  transactions: 'transactions',
  deliveries: 'deliveries'
};

class Authentication {
  role = {
    name: '',
    dashboard: '',
    products: '',
    product_actions: '',
    suppliers: '',
    supplier_actions: '',
    transactions: '',
    deliveries: '',
    created_at: '',
    updated_at: '',
    deleted_at: ''
  };

  async fetchPermissions() {
    await getCurrentRole().then((response) => {
      if (response.success == 1) {
        if (response.userDeletedAt != null) {
        } else {
          Object.keys(AUTH_KEY).forEach((attr) => {
            this.role[AUTH_KEY[attr]] = response.data[AUTH_KEY[attr]];
          });
        }
      }
    });
  }

  isEmpty() {
    return this.role.name == '';
  }

  isAllowed(permission) {
    return permission == ALLOWED;
  }

  isDenied(permission) {
    return permission == DENIED || permission == NOT_SET;
  }
}

export default Authentication;
